.card-container {
    display: flex;
    justify-content: center;
    overflow: hidden; /* Hide non-active cards */
    width: 100%; /* Adjust as necessary */
    height: 400px; /* Adjust as necessary */
    background-color: #18183B;
}

.card {
    width: 99vw; /* Adjust as necessary */
    height: 400px; /* Adjust as necessary */
    min-width: 99vw;
    min-height: 400px;
    max-width: 99vw;
    max-height: 400px;
    position: absolute; /* Positioning for the sliding effect */
    opacity: 0; /* Non-active cards are hidden */
    transition: transform 0.5s ease; /* Smooth transition for sliding */
    border-radius: 0%;
    background-image: var(--card-bg-image); 
    background-size: cover; /* Ensure the image covers the entire card */
    background-repeat: 'no-repeat';
    background-position: center; /* Center the background image */
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    color: white; /* Text color */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position: relative; 
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}

.card-text {
    position: absolute; /* Position the text absolutely within the card-overlay */
    top: 80%; /* Position halfway down the parent */
    left: 20%; /* Position halfway across the parent */
    transform: translate(-50%, -50%); /* Center the div */
    z-index: 999; /* Ensure it's above the image */

    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background for the text */
    color: white; /* Ensures text is bright and visible */
    font-size: 18px; /* Larger font size for better readability */
    font-weight: bold;
    padding: 8px 50px; /* Spacing around the text */
    
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5); /* Optional: Adds shadow for depth */
    text-align: left; /* Ensures text is centered */
}

.card img {
    position: absolute; /* Position the image absolutely within the card */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    object-fit: contain; /* Or 'cover', depending on your needs */
    z-index: 998; /* Lower than the text */
}


/* For screens wider than 768px, 2 cards in a row */
@media (max-width: 768px) {
    .card {
        flex: 0 1 calc(50% - 40px); /* 2 cards per row */
    }
}

/* For screens wider than 480px, 1 card in a row */
@media (max-width: 480px) {
    .card {
        flex: 0 1 calc(100% - 40px); /* 1 card per row */
    }
}

.card.active {
    opacity: 1; /* Active card is fully visible */
    transform: scale(1); /* Active card is at full size */
}


.custom-pagination .page-item a.page-link {
    color: #EBDCB0; /* Text color */
    background-color: #181E37; /* Background color */
    border: 1px solid #dee2e6; /* Adjust border color as needed */
    padding: 5px 10px; /* Larger padding for bigger buttons */
    font-size: 16px; /* Larger font size for better readability */
    border-radius: 2px; /* Adjust border-radius for desired rounded corners */
    font-weight: bold;
}

.custom-pagination .page-item.active .page-link,
.custom-pagination .page-item.custom-active .page-link {
    color: #181E37; /* Background color for active page as text color */
    background-color: #EBDCB0; /* Text color for active page as background */
    border-color: #181E37; /* Adjust for active item border */
}

.custom-pagination .page-item.disabled .page-link {
    color: #6c757d; /* Disabled text color */
    background-color: #181E37; /* Disabled background color */
    pointer-events: none; /* Prevents clicking */
}

/* Add hover effect */
.custom-pagination .page-item a.page-link:hover {
    background-color: #f8f9fa; /* Lighter background on hover */
    color: #181E37; /* Darker text on hover */
}

.product-card {
    position: relative; /* Needed for positioning the pseudo-element */
    overflow: hidden; /* Ensures the overlay doesn't spill outside the card */
}

.product-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay */
    transition: opacity 0.3s ease; /* Transition for the hover effect */
    opacity: 1; /* Show the overlay on hover */
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(0.5px);
}
.product-card:hover::before {
    opacity: 1; /* Show the overlay on hover */
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
}

.view-details-link {
    margin-top: 4px;
    font-size: 12px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: #007bff; /* Initial text color */
    transition: color 0.4s ease;
}

.view-details-link a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
}

.view-details-link:hover {
    color: #181E37; /* Text color on hover */
    font-weight: bold;

}

.view-details-link svg {
    margin-right: 8px; /* Space between icon and text */
    transition: transform 0.4s ease;
}


.category-tag {
    display: inline-block; /* Aligns the text within the tag */
    background-color: #00308fda; /* Background color of the tag */
    color: white; /* Text color */
    padding: 4px 20px; /* Space around the text */
    padding-top: 6px;
    padding-bottom: 4px;
    border-radius: 3px; /* Rounded corners */
    font-size: 10px; /* Font size */
    text-transform: uppercase; /* Uppercase text */
    cursor: pointer;
}
