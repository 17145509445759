.hero-background-contact {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('../bathtub_render.png')
}

.dark-overlay-contact {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Adjust the color and opacity as needed */
    z-index: 1;
}


/* Style for the hero text */
.hero-text-contact {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Martel' !important;
    font-size: 20px; /* Adjust as needed */
    line-height: 1.5; /* Spacing between lines */
    max-width: 600px; /* Maximum width of the text block */
    min-width: 300px;
    margin: auto;
    padding: 40px;
    padding-left: 100px;
    padding-right: 100px;
    background: rgba(255, 252, 244, 0.6); /* Optional: subtle background for text readability */
    border-radius: 10px; /* Optional: rounded corners */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Initial subtle shadow */
    animation: fadeIn 0.5s ease-in-out;
    z-index: 999;
}

/* Fade in animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}


/* Style for address, phone, and email */
.hero-text-contact p {
    margin-bottom: 15px;
}

/* Style for business hours */
.hero-text-contact .business-hours {
    font-weight: bold;
}

.hero-text-contact:hover {
    transform: translate(-50%, -50%) scale(1.01); /* Combine scaling and translation */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

