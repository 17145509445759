.hero-background {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('../bathtub_with_cabinet.png')
}

.dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Adjust the color and opacity as needed */
    z-index: 1;
}

.hero-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

/* Headline hover effect */
.hero-text h1:hover {
    color: #FDE4AA; /* Change to a different color */
    cursor: pointer;
}

/* Fade in animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.hero-text {
    animation: fadeIn 2s ease-in-out;
}

/* Initial state of paragraph */
.hero-text p {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-out;
}

/* State of paragraph when it should appear */
.hero-text p.appear {
    opacity: 1;
    transform: translateY(0);
}

/* Style for button */
.btn {
    box-shadow: 0 4px 8px rgba(254, 244, 220, 0.2); /* Shadow effect */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover effect for button */
.btn:hover {
    background-color: #FDE4AA; /* Change to a different color */
    transform: scale(1.05); /* Slightly increase size */
    transition: all 0.3s ease;
    box-shadow: 0 6px 12px rgba(254, 244, 220, 0.4); /* Increase shadow size */
}

.hero-background {
    position: relative;
    overflow: hidden;
}

.hero-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(circle at var(--mouse-x, 0) var(--mouse-y, 0), #fff6e073, transparent 20%);
    transition: background-position 0.3s ease;
}