/* Custom class for navbar with padding on larger screens */
@media (min-width: 992px) { /* Adjust 992px to your desired breakpoint */
    .navbar-padding-large-screen {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

.navbar-nav .nav-link {
    transition: all 0.3s ease;
}

.navbar-nav .nav-link:hover {
    color: #FDE4AA; /* Change to a desired hover color */
    text-decoration: none; /* Or use 'none' if you don't want an underline */
    transform: scale(1.1); /* Slightly increase the size */
}

.navbar-nav .nav-link {
  position: relative;
  transition: all 0.3s ease;
}

.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #FDE4AA; /* Underline color */
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-nav .nav-link:hover::after {
  width: 100%;
  left: 0;
}

.navbar-brand:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.navbar-toggler.collapsed .fas {
  transform: rotate(0);
  transition: transform 0.3s ease;
}

.navbar-toggler .fas {
  transform: rotate(90deg); /* Adjust the degree of rotation as needed */
  transition: transform 0.3s ease;
}


.navbar-toggler .fas {
  transform: rotate(0); /* Initial state: horizontal */
  transition: transform 0.3s ease;
}

.navbar-toggler:not(.collapsed) .fas {
  transform: rotate(90deg); /* Rotated state */
}
